import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="configure" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void configureWithAppID(@NonNull final String appId);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MobileCore.configureWithAppId("1423ae38-8385-8963-8693-28375403491d");
`}</code></pre>
    <Variant platform="ios" task="configure" repeat="11" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func configureWith(appId: String)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`MobileCore.configureWith(appId: "1423ae38-8385-8963-8693-28375403491d")
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (void) configureWithAppId: (NSString* appId);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[AEPMobileCore configureWithAppId: @"1423ae38-8385-8963-8693-28375403491d"];
`}</code></pre>
    <p>{`Alternatively, you can also place the environment ID in your iOS project's `}<em parentName="p">{`Info.plist`}</em>{` with the `}<inlineCode parentName="p">{`ADBMobileAppID`}</inlineCode>{` key. When the SDK is initialized, the environment ID is automatically read from the `}<em parentName="p">{`Info.plist`}</em>{` file and the associated configuration.`}</p>
    <Variant platform="android" task="update" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void updateConfiguration(@NonNull final Map<String, Object> configMap);
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`HashMap<String, Object> data = new HashMap<String, Object>();
data.put("global.privacy", "optedout");
MobileCore.updateConfiguration(data);
`}</code></pre>
    <Variant platform="ios" task="update" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`@objc(updateConfiguration:)
static func updateConfigurationWith(configDict: [String: Any])
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let updatedConfig = ["global.privacy":"optedout"]
MobileCore.updateConfigurationWith(configDict: updatedConfig)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (void) updateConfiguration: (NSDictionary* __nullable) config;
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSDictionary *updatedConfig = @{@"global.privacy":@"optedout"};
[AEPMobileCore updateConfiguration:updatedConfig];
`}</code></pre>
    {/*- <Variant platform="react-native" task="update" repeat="5"/>
     #### JavaScript
     **Syntax**
     ```jsx
     updateConfiguration(configMap?: { string: any })
     ```
     **Example**
     ```jsx
     ACPCore.updateConfiguration({"global.privacy":"optedout"});
     ```
     <Variant platform="flutter" task="update" repeat="5"/>
     #### Dart
     **Syntax**
     ```dart
     static Future<void> updateConfiguration(Map<String, Object> configMap);
     ```
     **Example**
     ```dart
     FlutterACPCore.updateConfiguration({"global.privacy":"optedout"});
     ``` -*/}
    <Variant platform="android" task="bundle" repeat="5" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void configureWithFileInPath(@NonNull final String filePath)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`// Case 1: to use ADBMobileConfig.json in the assets folder
// No code is needed

// Case 2: to use a config json from a absolute path:
MobileCore.configureWithFileInPath("absolute/path/to/exampleJSONfile.json");

// Case 3: to use a config json in Assets folder
MobileCore.configureWithFileInAssets("exampleJSONfile.json");
`}</code></pre>
    <Variant platform="ios" task="bundle" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift-2"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func configureWith(filePath: String)
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`let filePath = Bundle.main.path(forResource: "ExampleJSONFile", ofType: "json")
if let filePath = filePath {
    MobileCore.configureWith(filePath: filePath)
}
`}</code></pre>
    <h4 {...{
      "id": "objective-c-2"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`+ (void) configureWithFilePath: (NSString* __nullable) filepath;
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`NSString *filePath = [[NSBundle mainBundle] pathForResource:@"ExampleJSONFile" ofType:@"json"];
[AEPMobileCore configureWithFilePath: filePath];
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      