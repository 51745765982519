import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
import Alerts from '/src/pages/resources/alerts.md';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Variant platform="android-kotlin" task="add" repeat="3" mdxType="Variant" />
    <p>{`Add the required dependencies to your project by including them in the app's Gradle file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`implementation(platform("com.adobe.marketing.mobile:sdk-bom:3.+"))
implementation("com.adobe.marketing.mobile:core")
implementation("com.adobe.marketing.mobile:identity")
implementation("com.adobe.marketing.mobile:analytics")
implementation("com.adobe.marketing.mobile:media")
`}</code></pre>
    <Alerts query="platform=android-gradle&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <Variant platform="android-groovy" task="add" repeat="3" mdxType="Variant" />
    <p>{`Add the required dependencies to your project by including them in the app's Gradle file.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`implementation platform('com.adobe.marketing.mobile:sdk-bom:3.+')
implementation 'com.adobe.marketing.mobile:core'
implementation 'com.adobe.marketing.mobile:identity'
implementation 'com.adobe.marketing.mobile:analytics'
implementation 'com.adobe.marketing.mobile:media'
`}</code></pre>
    <Alerts query="platform=android-gradle&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <Variant platform="ios-pods" task="add" repeat="2" mdxType="Variant" />
    <p>{`Add the required dependencies to your project using CocoaPods. Add following pods in your `}<inlineCode parentName="p">{`Podfile`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`use_frameworks!

target 'YourTargetApp' do
  pod 'AEPCore', '~> 5.0'
  pod 'AEPAnalytics', '~> 5.0'
  pod 'AEPMedia', '~> 5.0'
end
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      