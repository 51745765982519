import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const InlineNestedAlert = makeShortcode("InlineNestedAlert");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {/* InlineNestedAlert - Warning for dynamic dependency versions: Android Gradle */}
    <Variant platform="android-gradle" componentClass="InlineNestedAlert" repeat="1" mdxType="Variant" />
    <InlineNestedAlert variant="warning" header="false" iconPosition="left" mdxType="InlineNestedAlert">
      <p>{`Using dynamic dependency versions is `}<strong parentName="p">{`not`}</strong>{` recommended for production apps. Please read the `}<a parentName="p" {...{
          "href": "../resources/manage-gradle-dependencies.md"
        }}>{`managing Gradle dependencies guide`}</a>{` for more information.`}</p>
    </InlineNestedAlert>
    {/* InlineNestedAlert - Warning for registerExtension removal in v3.0.0 of Android mobile SDKs */}
    <Variant platform="android-register-extension" componentClass="InlineNestedAlert" repeat="1" mdxType="Variant" />
    <InlineNestedAlert variant="warning" header="true" iconPosition="left" mdxType="InlineNestedAlert">
      <p>{`This API has been deprecated starting in v2.0.0 and removed in v3.0.0 of the Android mobile extension.`}</p>
      <p>{`Use `}<a parentName="p" {...{
          "href": "../../home/base/mobile-core/api-reference.md#registerextensions"
        }}><inlineCode parentName="a">{`MobileCore.registerExtensions()`}</inlineCode></a>{` API instead.`}</p>
    </InlineNestedAlert>
    {/* InlineNestedAlert - Alert for initialize API's available android versions */}
    <Variant platform="initialize-android-version" componentClass="InlineNestedAlert" repeat="1" mdxType="Variant" />
    <InlineNestedAlert variant="warning" header="true" iconPosition="left" mdxType="InlineNestedAlert">
      <p>{`This API is available starting from `}<strong parentName="p">{`Android BOM version 3.8.0`}</strong>{`.`}</p>
    </InlineNestedAlert>
    {/* InlineNestedAlert - Alert for initialize API's available ios versions */}
    <Variant platform="initialize-ios-version" componentClass="InlineNestedAlert" repeat="1" mdxType="Variant" />
    <InlineNestedAlert variant="warning" header="true" iconPosition="left" mdxType="InlineNestedAlert">
      <p>{`This API is available starting from `}<strong parentName="p">{`iOS version 5.4.0`}</strong>{`.`}</p>
    </InlineNestedAlert>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      