import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
import Alerts from '/src/pages/resources/alerts.md';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Variant platform="android-java" task="add-simplified-initialization" repeat="2" mdxType="Variant" />
    <Alerts query="platform=initialize-android-version&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.LoggingMode;
import com.adobe.marketing.mobile.MobileCore;
...
import android.app.Application;
...
public class MainApp extends Application {
  @Override
  public void onCreate(){
    super.onCreate();
    MobileCore.setLogLevel(LoggingMode.DEBUG);
    MobileCore.initialize(this, "ENVIRONMENT_ID");
  }
}
`}</code></pre>
    <Variant platform="android-kotlin" task="add-simplified-initialization" repeat="2" mdxType="Variant" />
    <Alerts query="platform=initialize-android-version&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`import com.adobe.marketing.mobile.LoggingMode
import com.adobe.marketing.mobile.MobileCore
...
import android.app.Application
...

class MainApp : Application() {
  override fun onCreate() {
    super.onCreate()
    MobileCore.setLogLevel(LoggingMode.DEBUG)
    MobileCore.initialize(this, "ENVIRONMENT_ID")
  }
}
`}</code></pre>
    <Variant platform="ios-swift" task="add-simplified-initialization" repeat="2" mdxType="Variant" />
    <Alerts query="platform=initialize-ios-version&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`// AppDelegate.swift
import AEPCore
import AEPServices
...

final class AppDelegate: NSObject, UIApplicationDelegate {
  func application(_: UIApplication, didFinishLaunchingWithOptions _: [UIApplication.LaunchOptionsKey: Any]? = nil) -> Bool {
    MobileCore.setLogLevel(.debug)
    MobileCore.initialize(appId: "ENVIRONMENT_ID")
    ...
  }
}
`}</code></pre>
    <Variant platform="ios-objc" task="add-simplified-initialization" repeat="2" mdxType="Variant" />
    <Alerts query="platform=initialize-ios-version&componentClass=InlineNestedAlert" mdxType="Alerts" />
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`// AppDelegate.m
#import "AppDelegate.h"
@import AEPCore;
@import AEPServices;
...
@implementation AppDelegate
- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
  [AEPMobileCore setLogLevel: AEPLogLevelDebug];  
  [AEPMobileCore initializeWithAppId:@"ENVIRONMENT_ID" completion:^{
      NSLog(@"AEP Mobile SDK is initialized");
  }];
  ...
  return YES;
}
@end
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      