import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
import Tabs from './tabs/initialize.md';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabsBlock = makeShortcode("TabsBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabsBlock orientation="horizontal" slots="heading, content" repeat="4" mdxType="TabsBlock" />
    <p>{`Kotlin`}<br />{`(Android)`}</p>
    <Tabs query="platform=android-kotlin&task=add-simplified-initialization" mdxType="Tabs" />
    <p>{`Java`}<br />{`(Android)`}</p>
    <Tabs query="platform=android-java&task=add-simplified-initialization" mdxType="Tabs" />
    <p>{`Swift`}<br />{`(iOS)`}</p>
    <Tabs query="platform=ios-swift&task=add-simplified-initialization" mdxType="Tabs" />
    <p>{`Objective-C`}<br />{`(iOS)`}</p>
    <Tabs query="platform=ios-objc&task=add-simplified-initialization" mdxType="Tabs" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      