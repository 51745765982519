import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" api="extension-version" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static String extensionVersion();
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "kotlin"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Messaging.extensionVersion()
`}</code></pre>
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`Messaging.extensionVersion();
`}</code></pre>
    <Variant platform="ios" api="extension-version" repeat="10" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public static let extensionVersion
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`Messaging.extensionVersion
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`public static let extensionVersion
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`[AEPMobileMessaging extensionVersion];
`}</code></pre>
    <Variant platform="android" api="handle-notification-response" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "java-2"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void handleNotificationResponse(final Intent intent,
                                         final boolean applicationOpened,
                                         final String customActionId);
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Parameter`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`intent`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Intent`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The intent contains information related to the messageId and the data.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`applicationOpened`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Shows whether the application has been opened or not.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`actionId`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`String`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The ID of the custom action.`}</td>
        </tr>
      </tbody>
    </table>
    <Variant platform="ios" api="handle-notification-response" repeat="7" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`static func handleNotificationResponse(_ response: UNNotificationResponse,
                                       urlHandler: ((URL) -> Bool)? = nil,
                                          closure: ((PushTrackingStatus) -> Void)? = nil)
`}</code></pre>
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-objc"
      }}>{`+ (void) handleNotificationResponse: (UNNotificationResponse *) response
                         urlHandler: (BOOL (^)(NSURL *url)) handler 
                            closure: (void (^)(PushTrackingStatus status)) closure;
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Parameter`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Type`}</strong></th>
          <th parentName="tr" {...{
            "align": "left"
          }}><strong parentName="th">{`Description`}</strong></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`response`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`UNNotificationResponse`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An object containing information about the push notification details.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`urlHandler`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`((URL) -> Bool)?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An optional method to handle the actionable URL from the push notification.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`closure`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`((PushTrackingStatus) -> Void)?`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An optional callback with `}<inlineCode parentName="td">{`PushTrackingStatus`}</inlineCode>{` representing the tracking status of the interacted notification.`}</td>
        </tr>
      </tbody>
    </table>
    <Variant platform="android" api="register-extension" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "java-3"
    }}>{`Java`}</h4>
    <p><strong parentName="p">{`Syntax`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public static void registerExtension();
`}</code></pre>
    <p><strong parentName="p">{`Example`}</strong></p>
    <h4 {...{
      "id": "kotlin-1"
    }}>{`Kotlin`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-kotlin"
      }}>{`Messaging.registerExtension()
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      