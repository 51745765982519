import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="usage" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`import com.adobe.marketing.mobile.services.NetworkRequest;
import com.adobe.marketing.mobile.services.ServiceProvider;

final int CONNECTION_TIMEOUT_MS = 5000;
final int READ_TIMEOUT_MS = 5000;
final String URL_TO_CONNECT = "URL_TO_CONNNECT_TO";
final byte[] body = "SampleRequestBody".getBytes(StandardCharsets.UTF_8);
final Map<String, String> headers = new HashMap<>();
headers.put("myCustomHeaderName", "myCustomHeaderValue");

// Create a NetworkRequest
final NetworkRequest networkRequest = new NetworkRequest(URL_TO_CONNECT, HttpMethod.GET, body, headers, CONNECTION_TIMEOUT_MS, READ_TIMEOUT_MS);

// Make the request
ServiceProvider.getInstance().getNetworkService().connectAsync(networkRequest, response -> {
                    // handle connection response

                    // close the response
                    if (response != null) {
                        response.close();
                    }
                });
`}</code></pre>
    <Variant platform="ios" task="usage" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import AEPServices

// Create your \`NetworkRequest\`, for more details see \`NetworkRequest.swift\`
let networkRequest = NetworkRequest(url: url, httpMethod: .get, httpHeaders: headers)

// Get an instance of the current network service
let networkService = ServiceProvider.shared.networkService

// Make a request
networkService.connectAsync(networkRequest: networkRequest) { httpConnection in
  // handle \`httpConnection\`
}
`}</code></pre>
    <Variant platform="android" task="override" repeat="7" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <ol>
      <li parentName="ol">{`Create a custom implementation of `}<inlineCode parentName="li">{`HttpConnecting`}</inlineCode>{` that represents a response to a network request. Also, create antoher implementation of `}<inlineCode parentName="li">{`HttpConnecting`}</inlineCode>{` that represents the error response. They will be used to handle network completion when overriding the network stack in place of internal network connection implementation.`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`MyCustomResponse`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`class MyCustomResponse implements HttpConnecting {

    private final HttpsURLConnection connection;

    MyCustomResponse(final HttpsURLConnection connection) {
        this.connection = connection;
    }

    @Override
    public InputStream getInputStream() {
        try {
            return connection.getInputStream();
        } catch (IOException e) {
            // handle exception
        }
    }

    @Override
    public InputStream getErrorStream() {
        return connection.getErrorStream();
    }

    @Override
    public int getResponseCode() {
        try {
            return connection.getResponseCode();
        } catch (IOException e) {
            // handle exception
        }
    }

    @Override
    public String getResponseMessage() {
        try {
            return connection.getResponseMessage();
        } catch (IOException e) {
            // handle exception
        }
    }

    @Override
    public String getResponsePropertyValue(String responsePropertyKey) {
        return connection.getHeaderField(responsePropertyKey);
    }

    @Override
    public void close() {
        final InputStream inputStream = this.getInputStream();

        final InputStream errorStream = this.getErrorStream();
        if (inputStream != null) {
            try {
                inputStream.close();
            } catch (final Exception e) {
                // handle exceptions
            }
        }
        if (errorStream != null) {
            try {
                errorStream.close();
            } catch (final Exception e) {
                // handle exceptions
            }

            connection.disconnect();
        }
    }
}
`}</code></pre>
    <ul>
      <li parentName="ul">{`ErrorResponse`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`class ErrorResponse implements HttpConnecting {

    @Override
    public InputStream getInputStream() {
        return null;
    }

    @Override
    public InputStream getErrorStream() {
        return null;
    }

    @Override
    public int getResponseCode() {
        return -1;
    }

    @Override
    public String getResponseMessage() {
        return "";
    }

    @Override
    public String getResponsePropertyValue(String responsePropertyKey) {
        return "";
    }

    @Override
    public void close() { }
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Create a custom implementation of `}<inlineCode parentName="li">{`Networking`}</inlineCode>{` interface and, implement the `}<inlineCode parentName="li">{`connectAsync`}</inlineCode>{` method. The implementation of `}<inlineCode parentName="li">{`connectAsync`}</inlineCode>{` should handle the connection establishment with the details provided in the `}<inlineCode parentName="li">{`NetworkRequest`}</inlineCode>{` and, notify the caller of a response using the `}<inlineCode parentName="li">{`NetworkCallback`}</inlineCode>{` parameter.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`/**
 * A sample implementation of Networking to enable overriding the default implementation of network service.
 */
class MyCustomNetworkService implements Networking {

    private final ExecutorService executorService = //

    /**
     * Initiate an asynchronous network request.
     *
     * @param request {@link NetworkRequest} used for network connection
     * @param callback {@link NetworkCallback} that will receive the {@link HttpConnecting} instance asynchronously.
     *
     */
    @Override
    public void connectAsync(final NetworkRequest request, final NetworkCallback callback) {
        // Use an executor service for initiating the network request and dispatching the response.
        executorService.submit(
            () -> {
                try {
                    // 1. If the network is down, for example, if the device is in airplane mode, the callback should be invoked immediately with a null connection. When the null connection is passed to the callback, the SDK will treat it as a recoverable failure and handle it accordingly.
                    
                    // callback.call(null);

                    // 2. If the network is available, the SDK should send out the request and invoke the callback with the corresponding connection.
                    final HttpConnecting connection = doConnection(request);

                    if (callback != null) {
                      // If a callback was provided, invoke the callback with the connection
                      callback.call(connection);
                    } else {
                      // If no callback is passed by the client, close the connection.
                      connection.close();
                    }
                } catch (Exception e) {
                    // 3. The connectAsync method should never throw exceptions. Catch any exceptions and invoke the callback with an error response.
                    if (callback != null) {
                        callback.call(new ErrorResponse());
                    }
                }
        });
    }

    /**
     * Utility method to perform the network connection.
     * @praram - the \`NetworkRequest\` whose details should be used for connecting
     *
     * @return \`MyCustomResponse\` after making a connection
     */
    private MyCustomResponse doConnection(final NetworkRequest request) {
        HttpsURLConnection connection = null;

        try {

            final URL obj = new URL(request.getUrl());
            connection = (HttpsURLConnection) obj.openConnection();

            // Set the request method
            connection.setRequestMethod(request.getMethod().toString());

            // Set the read and connect timeouts
            connection.setConnectTimeout(request.getConnectTimeout());
            connection.setReadTimeout(request.getReadTimeout());

            // set the request properties
            final Set<Map.Entry<String, String>> entries = request.getHeaders().entrySet();
            for (Map.Entry<String, String> entry : entries) {
                connection.setRequestProperty(entry.getKey(), entry.getValue());
            }

            if (Build.VERSION.SDK_INT < Build.VERSION_CODES.KITKAT_WATCH) {
                connection.setSSLSocketFactory(TLSSocketFactory.getInstance());
            }

            final boolean  isPostRequest = "POST".equalsIgnoreCase(connection.getRequestMethod());
            if (isPostRequest && request.getBody() != null) {
                // If the method is POST, set the length before connection
                connection.setFixedLengthStreamingMode(request.getBody().length);
            }

            connection.connect();

            // if the command is POST, send the data to the URL.
            if (isPostRequest && request.getBody() != null) {
                // Consume the payload
                final OutputStream os = new BufferedOutputStream(connection.getOutputStream());
                os.write(request.getBody());
                os.flush();
                os.close();
            }

        } catch (Exception e) {
            // handle exceptions
        }

        // create and return a response object conforming to the \`HttpConnecting\` interface.
        return new MyCustomResponse(connection);
    }
}
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Override the default implementation of `}<inlineCode parentName="li">{`NetworkService`}</inlineCode>{` provided by `}<inlineCode parentName="li">{`ServiceProvider`}</inlineCode>{`. This step should occur prior to any other interactions with the SDK. While it is possible to register the network override at any point during the application lifecycle, the override will only function for network requests performed after the registration has taken place.`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class MyApp extends Application {

    @Override
    public void onCreate() {
        super.onCreate();

        // Set the network override prior to making any other calls to the SDK
        ServiceProvider.getInstance().setNetworkService(new MyCustomNetworkService());

        MobileCore.initialize(this, "ENVIRONMENT_ID");
    }
}
`}</code></pre>
    <Variant platform="ios" task="override" repeat="8" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <p>{`First, you must implement the `}<inlineCode parentName="p">{`Networking`}</inlineCode>{` protocol which has just one method: `}<inlineCode parentName="p">{`connectAsync(networkRequest: completionHandler:)`}</inlineCode>{`. Your implementation will probably be more robust, but the important aspects are highlighted below.`}</p>
    <ol>
      <li parentName="ol">{`You must use the `}<inlineCode parentName="li">{`AEPServices.NetworkRequest`}</inlineCode>{` to pass the relevant data needed to start a network request.`}</li>
      <li parentName="ol">{`You must call the completionHandler with an `}<inlineCode parentName="li">{`AEPServices.HttpConnection`}</inlineCode></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`import AEPServices

class SampleNetworkOverride: Networking {
    func connectAsync(networkRequest: AEPServices.NetworkRequest, completionHandler: ((AEPServices.HttpConnection) -> Void)?) {
        let urlRequest = URLRequest(url: networkRequest.url)
        let urlSession = URLSession(configuration: .default)
        let task = urlSession.dataTask(with: urlRequest) { data, response, error in
            if let completionHandler = completionHandler {
                let httpConnection = HttpConnection(data: data, response: response as? HTTPURLResponse, error: error)
                completionHandler(httpConnection)
            }
        }
        
        task.resume()
    }
}
`}</code></pre>
    <p>{`Once you have implemented the `}<inlineCode parentName="p">{`Networking`}</inlineCode>{` protocol, all that's left is to override the ServiceProvider's NetworkService as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`ServiceProvider.shared.networkService = SampleNetworkOverride()
`}</code></pre>
    <p>{`If you would like to revert back to the default `}<inlineCode parentName="p">{`NetworkService`}</inlineCode>{`, set the `}<inlineCode parentName="p">{`networkService`}</inlineCode>{` to nil.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`ServiceProvider.shared.networkService = nil
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      