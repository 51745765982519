import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/github/workspace/node_modules/@adobe/gatsby-theme-aio/src/components/MDXFilter/index.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Variant = makeShortcode("Variant");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Variant platform="android" task="enable" repeat="2" mdxType="Variant" />
    <h4 {...{
      "id": "java"
    }}>{`Java`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`MobileCore.setLogLevel(LoggingMode.DEBUG);
// MobileCore.setLogLevel(LoggingMode.VERBOSE);
// MobileCore.setLogLevel(LoggingMode.WARNING);
// MobileCore.setLogLevel(LoggingMode.ERROR);
`}</code></pre>
    <Variant platform="ios" task="enable" repeat="4" mdxType="Variant" />
    <h4 {...{
      "id": "swift"
    }}>{`Swift`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`MobileCore.setLogLevel(.debug)
// MobileCore.setLogLevel(.trace)
// MobileCore.setLogLevel(.warning)
// MobileCore.setLogLevel(.error)
`}</code></pre>
    <h4 {...{
      "id": "objective-c"
    }}>{`Objective-C`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`[AEPMobileCore setLogLevel:AEPLogLevelDebug];
// [AEPMobileCore setLogLevel:AEPLogLevelTrace];
// [AEPMobileCore setLogLevel:AEPLogLevelWarning];
// [AEPMobileCore setLogLevel:AEPLogLevelError];
`}</code></pre>
    {/* 
     <Variant platform="react-native" task="enable" repeat="2"/>
     #### Javascript
     ```jsx
     ACPCore.setLogLevel(ACPMobileLogLevel.DEBUG);
     //ACPCore.setLogLevel(ACPMobileLogLevel.VERBOSE);
     //ACPCore.setLogLevel(ACPMobileLogLevel.WARNING);
     //ACPCore.setLogLevel(ACPMobileLogLevel.ERROR);
     ``` */}
    {/* <Variant platform="flutter" task="enable" repeat="2"/>
     #### Dart
     ```dart
     FlutterACPCore.setLogLevel(ACPLoggingLevel.DEBUG);
     //FlutterACPCore.setLogLevel(ACPLoggingLevel.VERBOSE);
     //FlutterACPCore.setLogLevel(ACPLoggingLevel.WARNING);
     //FlutterACPCore.setLogLevel(ACPLoggingLevel.ERROR);
     ``` */}
    {/* <Variant platform="cordova" task="enable" repeat="2"/>
     #### Cordova
     ```javascript
     ACPCore.setLogLevel(ACPCore.ACPMobileLogLevelError, successCallback, errorCallback);
     ACPCore.setLogLevel(ACPCore.ACPMobileLogLevelWarning, successCallback, errorCallback);
     ACPCore.setLogLevel(ACPCore.ACPMobileLogLevelDebug, successCallback, errorCallback);
     ACPCore.setLogLevel(ACPCore.ACPMobileLogLevelVerbose, successCallback, errorCallback);
     ```
     <Variant platform="unity" task="enable" repeat="2"/>
     #### C#
     ```csharp
     ACPCore.SetLogLevel(ACPCore.ACPMobileLogLevel.ERROR);
     ACPCore.SetLogLevel(ACPCore.ACPMobileLogLevel.WARNING);
     ACPCore.SetLogLevel(ACPCore.ACPMobileLogLevel.DEBUG);
     ACPCore.SetLogLevel(ACPCore.ACPMobileLogLevel.VERBOSE);
     ``` */}
    <Variant platform="android" task="metrics" repeat="7" mdxType="Variant" />
    <h4 {...{
      "id": "java-1"
    }}>{`Java`}</h4>
    <p>{`With the `}<inlineCode parentName="p">{`onResume`}</inlineCode>{` function, start Lifecycle data collection:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override  
   public void onResume() {  
      MobileCore.setApplication(getApplication());
      MobileCore.lifecycleStart(null);
   }
`}</code></pre>
    <p>{`Setting the application is only necessary on activities that are entry points for your application. However, setting the application on each `}<inlineCode parentName="p">{`Activity`}</inlineCode>{` has no negative impact and ensures that the SDK always has the necessary reference to your application. As a result, you should call `}<inlineCode parentName="p">{`setApplication`}</inlineCode>{` on each of your activities.`}</p>
    <p>{`You can use the `}<inlineCode parentName="p">{`onPause`}</inlineCode>{` function to pause the lifecycle data collection:`}</p>
    <p>{`To ensure accurate session and crash reporting, this call must be added to every `}<inlineCode parentName="p">{`Activity`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`@Override
   public void onPause() {
      MobileCore.lifecyclePause();
   }
`}</code></pre>
    <Variant platform="ios" task="metrics" repeat="32" mdxType="Variant" />
    <h4 {...{
      "id": "swift-1"
    }}>{`Swift`}</h4>
    <ol>
      <li parentName="ol">{`Register the Lifecycle extension with the SDK Core by adding the following to your app's `}<inlineCode parentName="li">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` delegate method. This will register the extension with Core and begin Lifecycle event processing:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
    MobileCore.registerExtensions([Lifecycle.self, ...], {
        ...
    }
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Start Lifecycle data collection by calling `}<inlineCode parentName="li">{`lifecycleStart:`}</inlineCode>{` from within the callback of the `}<inlineCode parentName="li">{`MobileCore.registerExtensions`}</inlineCode>{` method in your app's `}<inlineCode parentName="li">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` delegate method.`}</li>
    </ol>
    <p>{`If your iOS application supports background capabilities, your `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` method might be called when iOS launches your app in the background. If you do not want background launches to count towards your lifecycle metrics, then `}<inlineCode parentName="p">{`lifecycleStart:`}</inlineCode>{` should only be called when the application state is not equal to `}<inlineCode parentName="p">{`UIApplicationStateBackground`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplicationLaunchOptionsKey: Any]?) -> Bool {
    let appState = application.applicationState
    MobileCore.registerExtensions([Lifecycle.self, ...], {
        if appState != .background {
        // only start lifecycle if the application is not in the background
        MobileCore.lifecycleStart(additionalContextData: nil)
        }
    }
}
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`When launched, if your app is resuming from a backgrounded state, iOS might call your `}<inlineCode parentName="li">{`applicationWillEnterForeground:`}</inlineCode>{` delegate method. You also need to call `}<inlineCode parentName="li">{`lifecycleStart:`}</inlineCode>{`, but this time you do not need all of the supporting code that you used in `}<inlineCode parentName="li">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{`:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func applicationWillEnterForeground(_ application: UIApplication) {
    MobileCore.lifecycleStart(additionalContextData: nil)
}
`}</code></pre>
    <p>{`In iOS 13 and later, for a scene-based application, use the `}<inlineCode parentName="p">{`UISceneDelegate`}</inlineCode>{`'s `}<inlineCode parentName="p">{`sceneWillEnterForeground`}</inlineCode>{` method as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func sceneWillEnterForeground(_ scene: UIScene) {
    MobileCore.lifecycleStart(additionalContextData: nil)
}
`}</code></pre>
    <p>{`For more information on handling foregrounding applications with Scenes, refer to Apple's documentation `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/app_and_environment/scenes/preparing_your_ui_to_run_in_the_foreground"
      }}>{`here`}</a></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`When the app enters the background, pause Lifecycle data collection from your app's `}<inlineCode parentName="li">{`applicationDidEnterBackground:`}</inlineCode>{` delegate method:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func applicationDidEnterBackground(_ application: UIApplication) {
    MobileCore.lifecyclePause()
}
`}</code></pre>
    <p>{`In iOS 13 and later, for a scene-based application, use the `}<inlineCode parentName="p">{`UISceneDelegate`}</inlineCode>{`'s `}<inlineCode parentName="p">{`sceneDidEnterBackground`}</inlineCode>{` method as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`func sceneDidEnterBackground(_ scene: UIScene) {
    MobileCore.lifecyclePause()
}
`}</code></pre>
    <p>{`For more information on handling backgrounding applications with Scenes, refer to Apple's documentation `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/app_and_environment/scenes/preparing_your_ui_to_run_in_the_background"
      }}>{`here`}</a></p>
    <h4 {...{
      "id": "objective-c-1"
    }}>{`Objective-C`}</h4>
    <ol>
      <li parentName="ol">{`Register the Lifecycle extension with the SDK Core by adding the following to your app's `}<inlineCode parentName="li">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` delegate method. This will register the extension with Core and begin Lifecycle event processing:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
    // register the lifecycle extension
[AEPMobileCore registerExtensions:@[AEPMobileLifecycle.class, ...] completion:^{
    ...
}];
return YES;
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Start Lifecycle data collection by calling `}<inlineCode parentName="li">{`lifecycleStart:`}</inlineCode>{` from within the callback of the `}<inlineCode parentName="li">{`AEPMobileCore::registerExtensions:`}</inlineCode>{` method in your app's `}<inlineCode parentName="li">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` delegate method.`}</li>
    </ol>
    <p>{`If your iOS application supports background capabilities, your `}<inlineCode parentName="p">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{` method might be called when iOS launches your app in the background. If you do not want background launches to count towards your lifecycle metrics, then `}<inlineCode parentName="p">{`lifecycleStart:`}</inlineCode>{` should only be called when the application state is not equal to `}<inlineCode parentName="p">{`UIApplicationStateBackground`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (BOOL) application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
    // register the lifecycle extension, and begin event processing with Core
    const UIApplicationState appState = application.applicationState;
    [AEPMobileCore registerExtensions:@[AEPMobileLifecycle.class, ...] completion:^{
    // only start lifecycle if the application is not in the background
    if (appState != UIApplicationStateBackground) {
        [AEPMobileCore lifecycleStart:nil];
    }
    }];
}
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`When launched, if your app is resuming from a backgrounded state, iOS might call your `}<inlineCode parentName="li">{`applicationWillEnterForeground:`}</inlineCode>{` delegate method. You also need to call `}<inlineCode parentName="li">{`lifecycleStart:`}</inlineCode>{`, but this time you do not need all of the supporting code that you used in `}<inlineCode parentName="li">{`application:didFinishLaunchingWithOptions:`}</inlineCode>{`:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) applicationWillEnterForeground:(UIApplication *)application {
    [AEPMobileCore lifecycleStart:nil];
}
`}</code></pre>
    <p>{`In iOS 13 and later, for a scene-based application, use the `}<inlineCode parentName="p">{`UISceneDelegate`}</inlineCode>{`'s `}<inlineCode parentName="p">{`sceneWillEnterForeground`}</inlineCode>{` method as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) sceneWillEnterForeground:(UIScene *)scene {
    [AEPMobileCore lifecycleStart:nil];
}
`}</code></pre>
    <p>{`For more information on handling foregrounding applications with Scenes, refer to Apple's documentation `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/app_and_environment/scenes/preparing_your_ui_to_run_in_the_foreground"
      }}>{`here`}</a></p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`When the app enters the background, pause Lifecycle data collection from your app's `}<inlineCode parentName="li">{`applicationDidEnterBackground:`}</inlineCode>{` delegate method:`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) applicationDidEnterBackground:(UIApplication *)application {
    [AEPMobileCore lifecyclePause];
}
`}</code></pre>
    <p>{`In iOS 13 and later, for a scene-based application, use the `}<inlineCode parentName="p">{`UISceneDelegate`}</inlineCode>{`'s `}<inlineCode parentName="p">{`sceneDidEnterBackground`}</inlineCode>{` method as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-objectivec"
      }}>{`- (void) sceneDidEnterBackground:(UIScene *)scene {
    [AEPMobileCore lifecyclePause];
}
`}</code></pre>
    <p>{`For more information on handling backgrounding applications with Scenes, refer to Apple's documentation `}<a parentName="p" {...{
        "href": "https://developer.apple.com/documentation/uikit/app_and_environment/scenes/preparing_your_ui_to_run_in_the_background"
      }}>{`here`}</a></p>
    {/* <Variant platform="react-native" task="metrics" repeat="6"/>
     #### JavaScript
     You should implement Lifecycle metrics in native code. However, Lifecycle's APIs are available in Javascript if it fits your use case.
     **Starting a Lifecycle event**
     ```jsx
     ACPCore.lifecycleStart({"lifecycleStart": "myData"});
     ```
     **Pausing a Lifecycle event**
     ```jsx
     ACPCore.lifecyclePause();
     ``` */}
    {/* <Variant platform="flutter" task="metrics" repeat="2"/>
     #### Flutter
     You need to implement Lifecycle in native Android and iOS code. For more information on implementing, please read the [Lifecycle documentation](../lifecycle-for-edge-network/index.md). */}
    {/* <Variant platform="cordova" task="metrics" repeat="4"/>
     #### Cordova
     You need to implement Lifecycle in native Android and iOS code. For more information on implementing, please read the [Lifecycle documentation](../lifecycle-for-edge-network/index.md).
     **Getting Lifecycle version**
     ```javascript
     ACPLifecycle.extensionVersion(function(version) {
        console.log(version);
     }, function(error) {
        console.log(error);
     });
     ```
     <Variant platform="unity" task="metrics" repeat="3"/>
     #### C#
     **Starting and pausing a lifecycle event**
     ```csharp
     private void OnApplicationPause(bool pauseStatus)
     {
      if (pauseStatus)
      {
        ACPCore.LifecyclePause();
      }
      else
      {
        var cdata = new Dictionary<string, string>();
        cdata.Add("launch.data", "added");
        ACPCore.LifecycleStart(cdata);
      }
     }
     ```
     <Variant platform="xamarin" task="metrics" repeat="7"/>
     #### C#
     **iOS**
     **Starting and pausing a lifecycle event**
     ```csharp
     public override void WillEnterForeground(UIApplication uiApplication)
     {
      base.WillEnterForeground(uiApplication);
      ACPCore.LifecycleStart(null);
     }
     public override void DidEnterBackground(UIApplication uiApplication)
     {
      base.DidEnterBackground(uiApplication);
      ACPCore.LifecycleStart(null);
     }
     ```
     **Android**
     **Starting and pausing a lifecycle event**
     ```csharp
     protected override void OnResume()
     {
      base.OnResume();
      ACPCore.LifecycleStart(null);
     }
     protected override void OnPause()
     {
      base.OnPause();
      ACPCore.LifecyclePause();
     }
     ``` */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      